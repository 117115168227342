import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

class SidebarContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideFinderInmailsIndicator: false,
    };
  }

  // static propTypes = {
  //   changeToDark: PropTypes.func.isRequired,
  //   changeToLight: PropTypes.func.isRequired,
  //   onClick: PropTypes.func.isRequired,
  // };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };

  removeInmailsIndicator = () => {
    this.setState({ hideFinderInmailsIndicator: true });
  };

  render() {
    // const { changeToDark, changeToLight } = this.props;
    const { data } = this.props;
    const { hideFinderInmailsIndicator } = this.state;
    const assetsRootUrl = process.env.REACT_APP_BOTTL_WEBROOT;

    // Obligatoire ainsi, car ne fonctionne pas par props bizarrement
    let user = localStorage.getItem('user');
    if (user) user = JSON.parse(user);

    return (
      <div className="sidebar__content">
        <div className="sidebar__avatar" />
        <ul className="sidebar__block">
          {process.env.DASHBOARD_ENV === 'bottl' ? (
            <React.Fragment>
              <SidebarLink
                icon="home"
                title="Tableau de bord"
                route="/dashboard"
                onClick={this.hideSidebar}
              />
              <SidebarCategory
                image={`${assetsRootUrl}/assets/img/marques/bottl.window-icon.png`}
                title="Bottl.window"
                style={{ color: 'black', fontWeight: 'bold' }}
              >
                {data &&
                  Object.keys(data)
                    .filter((k) => ['campaigns'].includes(k))
                    .map((key) => {
                      const item = data[key];
                      return (
                        <SidebarCategory title={item.label} key={key}>
                          {item.selections && (
                            <SidebarLink
                              title={`${item.selections.label}`}
                              route={`/${item.slug}/selections`}
                              onClick={this.hideSidebar}
                            />
                          )}
                          {item.preparation && (
                            <SidebarLink
                              title={`${item.preparation.label}`}
                              route={`/${item.slug}/preparation`}
                              onClick={this.hideSidebar}
                              newLink={item.preparation.collection.length > 0}
                            />
                          )}
                          <SidebarLink
                            title={`${item.pending.label}`}
                            route={`/${item.slug}/pending`}
                            onClick={this.hideSidebar}
                          />
                          <SidebarLink
                            title={`${item.completed.label}`}
                            route={`/${item.slug}/completed`}
                            onClick={this.hideSidebar}
                          />
                        </SidebarCategory>
                      );
                    })}
                {/* <SidebarCategory title="Vitrines cavistes"> */}
                <SidebarLink
                  dist
                  title="Créer une campagne"
                  route="/simulateur"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Pré-sélections proposées"
                  route="/selections"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Liste de simulations"
                  route="/simulations"
                  onClick={this.hideSidebar}
                />

                {data &&
                  Object.keys(data)
                    .filter((k) => ['devis'].includes(k))
                    .map((key) => {
                      const item = data[key];
                      return (
                        <SidebarCategory title={item.label} key={key}>
                          {item.selections && (
                            <SidebarLink
                              title={`${item.selections.label}`}
                              route={`/${item.slug}/selections`}
                              onClick={this.hideSidebar}
                            />
                          )}
                          {item.preparation && (
                            <SidebarLink
                              title={`${item.preparation.label}`}
                              route={`/${item.slug}/preparation`}
                              onClick={this.hideSidebar}
                              newLink={item.preparation.collection.length > 0}
                            />
                          )}
                          <SidebarLink
                            title={`${item.pending.label}`}
                            route={`/${item.slug}/pending`}
                            onClick={this.hideSidebar}
                          />
                          <SidebarLink
                            title={`${item.completed.label}`}
                            route={`/${item.slug}/completed`}
                            onClick={this.hideSidebar}
                          />
                        </SidebarCategory>
                      );
                    })}

                {/* </SidebarCategory> */}
                {/* <SidebarLink */}
                {/*   title="Dégustations produit" */}
                {/*   route="/degustation/produit" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Sac bouteilles" */}
                {/*   route="/sac/bouteilles" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Vitrines chez Monop'" */}
                {/*   route="/vitrines/chez/monop" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Vitrines chez Nysa" */}
                {/*   route="/vitrines/chez/nysa" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Présentoirs sur compt..." */}
                {/*   route="/presentoirs/sur/comptoir" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
              </SidebarCategory>
              <SidebarCategory
                image={`${assetsRootUrl}/assets/img/marques/bottl.finder-icon.png`}
                style={{ color: 'black', fontWeight: 'bold' }}
                title="Bottl.finder"
                hasBadgeNew
              >
                <SidebarLink
                  title="Tableau de bord"
                  route="/finder/dashboard"
                  onClick={() => {
                    this.hideSidebar();
                  }}
                />

                <SidebarLink
                  title="Accéder à la plateforme"
                  route={`${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/`}
                  target="_blank"
                  onClick={() => {
                    this.hideSidebar();
                  }}
                  dist
                />

                <SidebarLink
                  title="Messagerie"
                  route="/finder/inmails"
                  onClick={() => {
                    this.removeInmailsIndicator();
                    this.hideSidebar();
                  }}
                  newLink={
                    data &&
                    data.finder &&
                    data.finder.inmails_indicator &&
                    !hideFinderInmailsIndicator
                  }
                />
                {data && data.finder && data.finder.subscription && (
                  <SidebarLink
                    title="Favoris"
                    route="/finder/bookmarks"
                    onClick={this.hideSidebar}
                  />
                )}

                {data &&
                  data.finder &&
                  data.finder.subscription &&
                  data.finder.subscription.plan &&
                  ['Professional', 'Entreprise'].includes(
                    data.finder.subscription.plan.name
                  ) && (
                    <SidebarCategory title={'Mes bases'} key={'Mes bases'}>
                      <SidebarLink
                        title="Gérer"
                        route="/finder/intelligence"
                        onClick={() => {
                          this.hideSidebar();
                        }}
                      />
                      <SidebarLink
                        title="Comparer"
                        route="/finder/vision"
                        onClick={() => {
                          this.hideSidebar();
                        }}
                      />
                    </SidebarCategory>
                  )}
                {data &&
                  data.finder &&
                  data.finder.subscription &&
                  data.finder.subscription.is_owner == true &&
                  data.finder.subscription.plan.is_free != true && (
                    <SidebarLink
                      title="Équipe"
                      route="/finder/team"
                      onClick={this.hideSidebar}
                    />
                  )}
                <SidebarLink
                  title="Abonnement"
                  route="/finder/subscription"
                  onClick={this.hideSidebar}
                />
                {data &&
                  data.finder &&
                  data.finder.subscription &&
                  data.finder.subscription.is_owner == true &&
                  data.finder.subscription.plan.is_free != true && (
                    <SidebarLink
                      title="Factures"
                      route="/finder/invoices"
                      onClick={this.hideSidebar}
                    />
                  )}

                {/*                 {data && */}
                {/*                   data.finder && */}
                {/*                   data.finder.subscription && */}
                {/*                   data.finder.subscription.plan && */}
                {/*                   ['Professional', 'Entreprise'].includes( */}
                {/*                     data.finder.subscription.plan.name */}
                {/*                   ) && ( */}
                {/*                     <SidebarLink */}
                {/*                       title="Finder Vision" */}
                {/*                       route="/finder/vision" */}
                {/*                       onClick={() => { */}
                {/*                         this.hideSidebar(); */}
                {/*                       }} */}
                {/*                     /> */}
                {/*                   )} */}
              </SidebarCategory>

              <SidebarCategory
                image={`${assetsRootUrl}/assets/img/marques/bottl.discover-icon.png`}
                style={{ color: 'black', fontWeight: 'bold' }}
                title="Bottl.discover"
              >
                {/* <SidebarCategory */}
                {/*   // icon="history" */}
                {/*   title="Mon Site capsule" */}
                {/* > */}
                <SidebarLink
                  title="Créer un site capsule"
                  route="/brandpages/new"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Liste de mes sites"
                  route="/brandpages/list"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Abonnement(s)"
                  route="/brandpages/subscriptions"
                  onClick={this.hideSidebar}
                />
                {/* </SidebarCategory> */}
                {/* <SidebarLink */}
                {/*   title="Webinaires" */}
                {/*   route="/webinaires" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Newsletter" */}
                {/*   route="/newsletter" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Facebook+Instagram" */}
                {/*   route="/facebook/instagram" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink */}
                {/*   title="Publicité sur Bottl.fr" */}
                {/*   route="/publicite/sur/bottl" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
              </SidebarCategory>

              <SidebarCategory
                image={`${assetsRootUrl}/assets/img/marques/bottl.insights-icon.png`}
                style={{ color: 'black', fontWeight: 'bold' }}
                title="Bottl.insight"
              >
                {/* <SidebarLink */}
                {/*   title="Consulting" */}
                {/*   route="/consulting" */}
                {/*   onClick={this.hideSidebar} */}
                {/* /> */}
                {/* <SidebarLink
                  title="Panels cavistes"
                  route="/panels/cavistes"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Sondage cavistes"
                  route="/sondage/cavistes"
                  onClick={this.hideSidebar}
                /> */}
                <SidebarLink
                  title="Liste de mes études"
                  // route="/insight/campaigns"
                  route="/insights/studies"
                  onClick={this.hideSidebar}
                />
                {/* <SidebarLink */}
                {/*   title="Acheter une étude" */}
                {/*   route={`${process.env.REACT_APP_BOTTL_WEBROOT}/insight/etudes`} */}
                {/*   onClick={this.hideSidebar} */}
                {/*   dist */}
                {/* /> */}
                <SidebarLink
                  title="Factures"
                  route="/insight/invoices"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>

              {data && data.survey && data.survey.has_studies && (
                <SidebarCategory
                  image={`${assetsRootUrl}/assets/img/marques/bottl.survey-icon.png`}
                  style={{ color: 'black', fontWeight: 'bold' }}
                  title="Bottl.survey"
                >
                  <SidebarLink
                    title="Liste de mes études"
                    route="/survey/studies?for_survey=1"
                    onClick={this.hideSidebar}
                  />
                </SidebarCategory>
              )}

              {user && user.can_see_distributors == true && (
                <>
                  <br />
                  <hr />
                  <SidebarLink
                    title="Distributeurs en France"
                    route="/distributors"
                    onClick={this.hideSidebar}
                  />
                </>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <SidebarLink
                icon="home"
                title="Tableau de bord"
                route="/dashboard"
                onClick={this.hideSidebar}
              />
              <SidebarCategory icon="cog" title="Mes simulations">
                <SidebarLink
                  dist
                  title="Faire une simulation"
                  route="/simulateur"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Pré-sélections proposées"
                  route="/selections"
                  onClick={this.hideSidebar}
                />
                <SidebarLink
                  title="Ma liste de simulations"
                  route="/simulations"
                  onClick={this.hideSidebar}
                />
              </SidebarCategory>
              {data &&
                Object.keys(data).map((key) => {
                  const item = data[key];
                  return (
                    <SidebarCategory
                      icon={item.icon}
                      title={item.label}
                      key={key}
                    >
                      {item.selections && (
                        <SidebarLink
                          title={`${item.selections.label}`}
                          route={`/${item.slug}/selections`}
                          onClick={this.hideSidebar}
                        />
                      )}
                      {item.preparation && (
                        <SidebarLink
                          title={`${item.preparation.label}`}
                          route={`/${item.slug}/preparation`}
                          onClick={this.hideSidebar}
                          newLink={item.preparation.collection.length > 0}
                        />
                      )}
                      <SidebarLink
                        title={`${item.pending.label}`}
                        route={`/${item.slug}/pending`}
                        onClick={this.hideSidebar}
                      />
                      <SidebarLink
                        title={`${item.completed.label}`}
                        route={`/${item.slug}/completed`}
                        onClick={this.hideSidebar}
                      />
                    </SidebarCategory>
                  );
                })}
            </React.Fragment>
          )}
        </ul>
      </div>
    );
  }
}

export default SidebarContent;

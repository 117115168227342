import axios from 'axios';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import Loading from '../../App/Loading';
import Overview from '../Intelligence/Import/overview';
import EnrichedShopsMatTable from '../components/forEnrichedShops/Table/MatTable';
import GeographyUnitsMatTable from '../components/forGeographyUnits/Table/MatTable';
moment.locale('fr');

class Imports extends React.Component {
  state = {
    loaded: false,
    loading: false,
    subscription: null,
    imports: [],
    currentImport: null,
    showDetail: false,
  };

  _showNotification(w) {
    Swal.fire({
      icon: 'success',
      title: `Information`,
      html: w,
    });
  }

  _showError(w) {
    Swal.fire({
      icon: 'error',
      title: `Erreur`,
      html: w,
    });
  }

  constructor(props) {
    super(props);

    this.openImport = this.openImport.bind(this);
    this.shopOpen = this.shopOpen.bind(this);
    this.openGeographyUnit = this.openGeographyUnit.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true, loaded: false });

    const {
      authentication: { user },
    } = this.props;
    let endpoint = `finder/subscription/get`;

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
      })
      .then((res) => {
        this.setState({
          subscription: res.data.content.subscription,
        });

        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/list`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
              for_vision: true,
            }
          )
          .then((res) => {
            if (!this.props.match.params.id)
              this.setState({
                imports: res.data.content.imports,
                loaded: true,
                loading: false,
              });
            else {
              axios
                .post(
                  `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/get`,
                  {
                    id: user.id,
                    token: user.token,
                    passphrase: user.passphrase,
                    importId: this.props.match.params.id,
                  }
                )
                .then((resx) => {
                  this.setState({
                    loaded: true,
                    loading: false,
                    imports: res.data.content.imports,
                    currentImport: resx.data.content.import,
                    showDetail: resx.data.content.import.geography.length === 0,
                  });
                });
            }
          });
      })
      .catch((err) => {
        this.setState({
          loaded: true,
          loading: false,
        });
      });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.props.match.params.id
    ) {
      const {
        authentication: { user },
      } = this.props;

      if (this.state.imports.some((i) => i.pk == this.props.match.params.id))
        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/finder/imports/get`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
              importId: this.props.match.params.id,
            }
          )
          .then((resx) => {
            this.setState({
              loaded: true,
              loading: false,
              currentImport: resx.data.content.import,
              showDetail: resx.data.content.import.geography.length === 0,
            });
          });
    }
  }

  openImport(i) {
    this.props.history.push(`/finder/vision/imports/${i.pk}`);
  }

  shopOpen(id) {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/commerces/${id}-.html`,
      '_blank'
    );
  }

  openGeographyUnit(unit) {
    window.open(
      `${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/?ville=${unit.city}&import_id=${this.state.currentImport.pk}`,
      '_blank'
    );
  }

  render() {
    let {
      loaded,
      loading,
      subscription,
      imports,
      currentImport,
      showDetail,
    } = this.state;

    const identifiedShops = !currentImport
      ? []
      : currentImport.shops.filter((s) => s.fk_shop);

    const totalGeographyCount = !currentImport
      ? 0
      : currentImport.geography.reduce((a, b) => a + parseInt(b.total), 0);

    return (
      <Container className="dashboard">
        {!loaded && <Loading loaded={loaded} loading={loading} />}
        {/* FULL STATE */}
        {loaded &&
          subscription &&
          subscription.team.is_subscribed == true &&
          imports && (
            <React.Fragment>
              {/* 1ST ROW */}
              <Row>
                <Col>
                  <Row>
                    <Col md={8}>
                      <h3 className="page-title">
                        <>
                          {!currentImport && (
                            <>
                              Comparez vos bases avec celle de Finder
                              {!currentImport
                                ? ''
                                : ` > Import ${currentImport.pk}`}
                            </>
                          )}
                          {currentImport &&
                            `Import #${currentImport.pk} - Résultats`}
                        </>
                      </h3>
                      {!currentImport && imports.length > 0 && (
                        <p style={{ fontSize: '12.5pt' }}>
                          Croisez vos bases avec celle de Finder et identifiez
                          de nouvelles opportunités de développement commercial
                          <br />
                        </p>
                      )}
                    </Col>
                    <Col md={4} align="right">
                      {imports && !currentImport && imports.length > 0 && (
                        <>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => {
                              this.props.history.push('/finder/vision/import');
                            }}
                          >
                            Faire un nouvel import
                          </Button>
                        </>
                      )}
                      {currentImport && (
                        <>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            onClick={() => {
                              this.props.history.push('/finder/vision/imports');
                            }}
                          >
                            Fermer
                          </Button>
                          <Button
                            color="primary"
                            outline
                            size="sm"
                            href={`${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/?import_id=${currentImport.pk}`}
                            target="_blank"
                          >
                            Visualiser dans Finder
                          </Button>
                        </>
                      )}
                    </Col>
                  </Row>
                  {/* NO IMPORT EVER MADE */}
                  {imports.length === 0 && (
                    <Row>
                      <Col md={12}>
                        <div className="subscription-placeholder">
                          <div className="placeholder-wrapper">
                            <p
                              style={{ textAlign: 'center' }}
                              className="placeholder"
                            >
                              Ici, vous pouvez charger votre ou vos bases de
                              données de cavistes et les comparer avec Finder.
                              <br />
                              Cela vous permet de déterminer votre taux de
                              couverture par zone géographique, et découvrir de
                              nouvelles opportunités commerciales.
                              <br />
                              Nous ne récupérons aucune donnée, ce sont
                              strictement les vôtres.
                            </p>
                            <br />
                            <button
                              onClick={() => {
                                this.props.history.push(
                                  '/finder/vision/import'
                                );
                              }}
                              className="btn btn-primary"
                            >
                              Nouvel import
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )}
                  {/* LIST OF IMPORTS */}
                  {imports.length > 0 && !currentImport && (
                    <Row>
                      <Col md={12} style={{ marginTop: '24px' }}>
                        <p className="as-import-intro">
                          Voici vos bases importées que vous avez comparées avec
                          celle de Finder
                        </p>
                        <div className="as-imports-list">
                          {imports.map((i, idx) => (
                            <div
                              key={idx}
                              className="as-import"
                              onClick={(evt) => this.openImport(i)}
                            >
                              <div className="as-import-cell for-name for-vision">
                                {i.shops_commercial_status === 'prospects' && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    {' '}
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                                    />{' '}
                                  </svg>
                                )}
                                {i.shops_commercial_status === 'clients' && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                                    />
                                  </svg>
                                )}
                                {i.shops_commercial_status === 'all' && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25"
                                    />
                                  </svg>
                                )}
                                <div className="as-flex-col">
                                  <span>
                                    #{i.pk}
                                    {' - '}
                                    {
                                      {
                                        prospects: 'Import de prospects',
                                        clients: 'Import de clients',
                                        all: 'Import mixte',
                                      }[i.shops_commercial_status]
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="as-import-cell for-stats for-vision">
                                {i.line_count} ligne
                                {i.line_count > 1 ? 's' : ''}
                              </div>
                              <div className="as-import-cell for-date">
                                Importé le{' '}
                                {moment(i.created_at).format('DD/MM/YYYY')}
                              </div>
                              <div className="as-import-cell for-button">
                                <a
                                  href={`/finder/vision/imports/${i.pk}`}
                                  className="btn btn-primary"
                                >
                                  Voir l'import
                                </a>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Col>
                    </Row>
                  )}

                  {/* VIEW ONE IMPORT */}
                  {currentImport && (
                    <>
                      {/* METRICS */}
                      <Row className="px-0 w-100 d-flex col-md-12 mr-0 no-gutters as-finder-vision-metrics">
                        {/* 1 */}
                        <Col md={12} xl={3} lg={6} xs={12}>
                          <Card>
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <div className="card__title">
                                  <h5 className="bold-text">
                                    Nombre d'unités géographiques identifiées
                                    par Finder
                                  </h5>
                                </div>
                                <div className="dashboard__total">
                                  <p className="dashboard__total-stat">
                                    {currentImport.geography.length > 0 && (
                                      <>
                                        {currentImport.geography.length} ville
                                        {currentImport.geography.length != 1
                                          ? 's'
                                          : ''}
                                      </>
                                    )}
                                    {currentImport.geography.length === 0 &&
                                      'Aucune'}
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* 2 */}
                        <Col md={12} xl={3} lg={6} xs={12}>
                          <Card>
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <div className="card__title">
                                  <h5 className="bold-text">
                                    Nombre de caves identifiées par Finder
                                  </h5>
                                </div>
                                <div className="dashboard__total">
                                  <p className="dashboard__total-stat">
                                    {identifiedShops.length > 0 && (
                                      <>
                                        {identifiedShops.length} cave
                                        {identifiedShops.length > 1 ? `s` : ''}
                                        {` (${(
                                          (identifiedShops.length * 100) /
                                          currentImport.shops.length
                                        ).toFixed(0)}%)
                                            `}
                                      </>
                                    )}
                                    {identifiedShops.length === 0 && 'Aucune'}
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* 3 */}
                        <Col md={12} xl={3} lg={6} xs={12}>
                          <Card>
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <div className="card__title">
                                  <h5 className="bold-text">
                                    Nombre de caves non identifiées par Finder
                                  </h5>
                                </div>
                                <div className="dashboard__total">
                                  <p className="dashboard__total-stat">
                                    {currentImport.shops.length -
                                      identifiedShops.length >
                                      0 && (
                                      <>
                                        {currentImport.shops.length -
                                          identifiedShops.length}{' '}
                                        cave
                                        {currentImport.shops.length -
                                          identifiedShops.length >
                                        1
                                          ? 's'
                                          : ''}{' '}
                                        (
                                        {(
                                          ((currentImport.shops.length -
                                            identifiedShops.length) *
                                            100) /
                                          currentImport.shops.length
                                        ).toFixed(0)}
                                        %)
                                      </>
                                    )}
                                    {currentImport.shops.length -
                                      identifiedShops.length ===
                                      0 && 'Aucune'}
                                  </p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                        {/* 3 */}
                        <Col md={12} xl={3} lg={6} xs={12}>
                          <Card>
                            <CardBody className="dashboard__card-widget">
                              <div>
                                <div className="card__title">
                                  <h5 className="bold-text">
                                    Nombre de caves que Finder a et que vous
                                    n'avez pas
                                  </h5>
                                </div>
                                <div className="dashboard__total">
                                  <a
                                    className="dashboard__total-stat"
                                    href={`${process.env.REACT_APP_BOTTL_WEBROOT}/caves/rechercher/?import_id=${currentImport.pk}&only_unidentified=1`}
                                    target="_blank"
                                  >
                                    {totalGeographyCount > 0 && (
                                      <>
                                        {totalGeographyCount -
                                          identifiedShops.length >
                                        0 ? (
                                          <>
                                            {totalGeographyCount -
                                              identifiedShops.length}{' '}
                                            cave
                                            {totalGeographyCount -
                                              identifiedShops.length !=
                                            1
                                              ? 's'
                                              : ''}{' '}
                                          </>
                                        ) : (
                                          'Aucune'
                                        )}{' '}
                                      </>
                                    )}
                                    {totalGeographyCount === 0 &&
                                      'Non applicable'}
                                  </a>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <Row className="as-full-import">
                        <Col md={12}>
                          {identifiedShops.length > 0 && (
                            <>
                              {/* LIST OF GEOGRAPHY UNITS */}
                              <div className="as-import-shops-overview">
                                <GeographyUnitsMatTable
                                  history={this.props.history}
                                  shops={identifiedShops}
                                  data={currentImport.geography.map((u) => ({
                                    ...u,
                                    count_identified: identifiedShops.filter(
                                      (s) => s.locality === u.city
                                    ).length,
                                    rate_cover:
                                      identifiedShops.filter(
                                        (s) => s.locality === u.city
                                      ).length / parseInt(u.total),
                                  }))}
                                  title="Liste des unités géographiques détectées"
                                  onGeographyUnitOpen={this.openGeographyUnit}
                                  importId={currentImport.pk}
                                />
                              </div>

                              {/* LIST OF ENRICHED SHOPS */}
                              <div className="as-import-shops-overview">
                                <EnrichedShopsMatTable
                                  history={this.props.history}
                                  data={identifiedShops}
                                  title="Liste des caves identifiées par Finder"
                                  onShopOpen={this.shopOpen}
                                  onShopDelete={this.shopDelete}
                                  onShowUpdateForm={this.showUpdateForm}
                                  importId={currentImport.pk}
                                />
                              </div>
                            </>
                          )}

                          {/* FULL DETAILS */}
                          {!showDetail && (
                            <Row>
                              <Col md={12} align={'center'}>
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() => {
                                    this.setState({ showDetail: true });
                                  }}
                                >
                                  Voir le détail de l'import
                                </Button>
                              </Col>
                            </Row>
                          )}
                          {showDetail && (
                            <div className="as-import-full-details">
                              <div className="as-flex as-space-between as-align-center">
                                <p className="as-import-intro">
                                  Voici le détail de votre import
                                </p>
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() => {
                                    this.setState({ showDetail: false });
                                  }}
                                >
                                  Cacher le détail de l'import
                                </Button>
                              </div>
                              <Row>
                                <Col md={12}>
                                  {/* SETTINGS */}
                                  <div className="as-import-settings">
                                    <p>Paramètres de votre import</p>

                                    <div className="wrapper">
                                      <div className="as-import-setting">
                                        <span>Type de données</span>
                                        <span>
                                          {
                                            {
                                              prospects: 'Prospects',
                                              clients: 'Clients',
                                              all: 'Mixte',
                                            }[
                                              currentImport
                                                .shops_commercial_status
                                            ]
                                          }
                                        </span>
                                      </div>
                                      <div className="as-import-setting">
                                        <span>Méthode d'import</span>
                                        <span>
                                          {currentImport.type === 'file'
                                            ? 'Fichier'
                                            : 'Copier-coller'}
                                        </span>
                                      </div>
                                      {currentImport.type === 'file' && (
                                        <div className="as-import-setting">
                                          <span>Nom du fichier</span>
                                          <span>{currentImport.filename}</span>
                                        </div>
                                      )}
                                      <div className="as-import-setting">
                                        <span>Entêtes inclus</span>
                                        <span>
                                          {currentImport.has_header
                                            ? 'Oui'
                                            : 'Non'}
                                        </span>
                                      </div>
                                      <div className="as-import-setting">
                                        <span>Délimiteur</span>
                                        <span>
                                          {{
                                            ',': 'Virgule',
                                            ';': 'Point-virgule',
                                            '\t': 'Tabulation',
                                          }[currentImport.delimiter] || 'Aucun'}
                                        </span>
                                      </div>
                                      <div className="as-import-setting">
                                        <span>Nombre de lignes</span>
                                        <span>{currentImport.line_count}</span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              {/* OVERVIEW */}
                              <Overview _import={currentImport} isFull={true} />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
            </React.Fragment>
          )}
        {/* EMPTY VOID UNSUBSCRIBED STATE */}
        {loaded && (!subscription || subscription.team.is_subscribed == false) && (
          <div className="subscription-placeholder">
            <div className="placeholder-wrapper">
              <p className="placeholder">
                Vous n'avez aucun abonnement à Finder pour l'instant.
              </p>
              <br />
              <a
                href={`${process.env.REACT_APP_BOTTL_WEBROOT}/finder`}
                className="btn btn-primary"
              >
                Je m'abonne
              </a>
            </div>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps)(Imports);

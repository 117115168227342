import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { Container, Col, Row } from 'reactstrap';
import Loading from '../App/Loading';
import MatTable from './components/Table/MatTable';
import { changeSidebarVisibility } from '../../redux/actions/sidebarActions';

class InsightsStudies extends React.Component {
  state = {
    loaded: false,
    loading: true,
    collection: [],
    availableStudies: [],
    currentStudy: null,
    isForSurvey: false,
  };

  constructor(props) {
    super(props);

    this.showQuestions = this.showQuestions.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  componentDidMount() {
    const {
      authentication: { user },
    } = this.props;
    const endpoint = `insights/studies`;
    const isForSurvey = new URLSearchParams(window.location.search).has(
      'for_survey'
    );

    if (this.props.sidebar.collapse) {
      this.props.changeSidebarVisibility();
    }

    axios
      .post(`${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/${endpoint}`, {
        id: user.id,
        token: user.token,
        passphrase: user.passphrase,
        isForSurvey,
      })
      .then((res) => {
        axios
          .post(
            `${process.env.REACT_APP_BOTTL_API_ENDPOINT_V2}/insights/studies-available`,
            {
              id: user.id,
              token: user.token,
              passphrase: user.passphrase,
            }
          )
          .then((resx) => {
            this.setState({
              loaded: true,
              loading: false,
              collection: res.data.content.collection,
              availableStudies: resx.data.content.collection,
              isForSurvey,
            });
          });
      });
  }

  showQuestions(id) {
    const study = this.state.availableStudies.find((s) => s.id === id);
    this.setState({
      currentStudy: study,
    });
  }

  hidePopup() {
    this.setState({
      currentStudy: null,
    });
  }

  render() {
    const { loaded, loading, currentStudy, isForSurvey } = this.state;

    let collection = this.state.collection.filter(
      (s) => s.isSurvey == isForSurvey
    );

    return (
      <Container className="dashboard">
        <Loading loaded={loaded} loading={loading} />
        {loaded && (
          <React.Fragment>
            <Row>
              <Col md={12}>
                <h3 className="page-title">
                  Bottl.{isForSurvey ? 'Survey' : 'Insight'}
                </h3>
              </Col>
            </Row>
            <Row>
              {collection && collection.length > 0 && (
                <MatTable
                  history={this.props.history}
                  data={collection}
                  title={`Liste de mes études`}
                  type={this.props.location.pathname}
                />
              )}
            </Row>
            {!isForSurvey && (
              <Row>
                <MatTable
                  history={this.props.history}
                  data={this.state.availableStudies}
                  title={`Liste des études disponibles à l'achat`}
                  type={this.props.location.pathname}
                  forStudiesAvailable={true}
                  onShowQuestions={this.showQuestions}
                />
              </Row>
            )}
            {currentStudy && (
              <div className="as-insights-popup">
                <div className="as-overlay" onClick={this.hidePopup}></div>
                <div className="as-content">
                  <span className="as-title">Liste des questions</span>
                  <ul>
                    {currentStudy.questionsList.map((q) => (
                      <li key={q.id}>{q.label}</li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeSidebarVisibility: (state) => dispatch(changeSidebarVisibility(state)),
});
const mapStateToProps = (state) => ({
  ...state,
});
export default connect(mapStateToProps, mapDispatchToProps)(InsightsStudies);

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../Layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../LogIn/index';

import CampaignsDashboard from '../Campaigns/index';
import CampaignsCollection from '../Campaigns/collection';

import Campaign from '../Campaign/index';
import CampaignWindowDashboard from '../Campaign/shops/dashboard';
import CampaignChecklist from '../Campaign/checlist';
import CampaignSurveyQuestions from '../Survey/Questions/index';
import CampaignSurveyResults from '../Survey/Results/index';
import CampaignSurveyCreation from '../Survey/Creation/index';
import EdcResults from '../Campaign/edcResults';
import EdcVisualisation from '../Campaign/edcVisualisation';

import DevisCollection from '../Devis/collection';
import ContratsCollection from '../Contrats/collection';

import SelectionsCollection from '../Selections/collection';
import SelectionDetail from '../Selection/index';

import SimulationsCollection from '../Simulations/collection';
import SimulationDetail from '../Simulation/index';
import Simulator from '../Simulation/Simulator';

import Profile from '../Profile/index';
import Notifications from '../Notifications/index';

import BrandpagesCollection from '../Brandpages/index';
import Brandpage from '../Brandpages/item/index';
import BrandpagesNew from '../Brandpages/newItem';
import BrandpagesSubscriptions from '../Brandpages/subscriptions';
import BrandpagesSubscription from '../Brandpages/subscription';

import InsightCampaigns from '../Insight/index';
import InsightCampaign from '../Insight/campaign';
import InsightParticipation from '../Insight/participation';
import InsightInvoices from '../Insight/invoices';

import InsightsStudies from '../Insights/index';
import InsightsStudy from '../Insights/study';

import FinderInmails from '../Finder/inmails';
import FinderConversation from '../Finder/conversation';
import FinderTeam from '../Finder/team';
import FinderWelcome from '../Finder/welcome';
import FinderSubscription from '../Finder/subscription';
import FinderDashboard from '../Finder/dashboard';
import FinderBookmarks from '../Finder/bookmarks';
import FinderInvoices from '../Finder/invoices';

import FinderIntelligenceDashboard from '../Finder/Intelligence/dashboard';
import FinderIntelligenceImports from '../Finder/Intelligence/imports';
import FinderIntelligenceImport from '../Finder/Intelligence/Import';
import FinderIntelligenceImportCopyPaste from '../Finder/Intelligence/Import/importer.copypaste';
import FinderIntelligenceImportFile from '../Finder/Intelligence/Import/importer.file';

import FinderVisionDashboard from '../Finder/Vision/dashboard';
import FinderVisionImport from '../Finder/Vision/Import';
import FinderVisionImports from '../Finder/Vision/imports';

import Soon from '../Soon/Index';
import Errors from '../Error/index';

import DistributorsIndex from '../Distributors';

const Pages = (props) => (
  <Switch>
    {localStorage.getItem('user') ? (
      <React.Fragment>
        <Route exact path="/" component={CampaignsDashboard} />

        <Route path="/dashboard" component={CampaignsDashboard} />
        <Route path="/brandpages/list" component={BrandpagesCollection} />
        <Route path="/brandpage/:id" component={Brandpage} />
        <Route path="/brandpages/new" component={BrandpagesNew} />
        <Route
          path="/brandpages/subscriptions"
          component={BrandpagesSubscriptions}
        />
        <Route
          path="/brandpages/subscription/:id"
          component={BrandpagesSubscription}
        />
        <Route path="/campaigns/selections" component={SelectionsCollection} />
        <Route path="/campaigns/selection/:id" component={SelectionDetail} />
        <Route path="/campaigns/preparation" component={CampaignsCollection} />
        <Route path="/campaigns/pending" component={CampaignsCollection} />
        <Route path="/campaigns/completed" component={CampaignsCollection} />

        <Route path="/campaign/:id" component={Campaign} />
        <Route path="/checklist/campaign/:id" component={CampaignChecklist} />
        <Route
          path="/shop/:windowid/:campaignid/report"
          component={CampaignWindowDashboard}
        />
        <Route
          path="/survey/questions/:campaignid"
          component={CampaignSurveyQuestions}
        />
        <Route
          path="/survey/results/:campaignid"
          component={CampaignSurveyResults}
        />
        <Route
          path="/survey/creation/:campaignid"
          component={CampaignSurveyCreation}
        />
        <Route path="/edc/results/:campaignid" component={EdcResults} />
        <Route
          path="/edc/visualisation/:campaignid"
          component={EdcVisualisation}
        />

        <Route path="/contrats/pending" component={ContratsCollection} />
        <Route path="/contrats/completed" component={ContratsCollection} />

        <Route path="/devis/pending" component={DevisCollection} />
        <Route path="/devis/completed" component={DevisCollection} />

        <Route path="/selections" component={SelectionsCollection} />
        <Route path="/selection/:id" component={SelectionDetail} />

        <Route path="/finder/welcome" component={FinderWelcome} exact={true} />

        <Route path="/finder/inmails" component={FinderInmails} exact={true} />
        <Route path="/finder/inmails/:id" component={FinderConversation} />

        <Route path="/finder/team" component={FinderTeam} exact={true} />
        <Route path="/finder/team/:id" component={FinderTeam} />

        <Route
          path="/finder/bookmarks"
          component={FinderBookmarks}
          exact={true}
        />
        <Route path="/finder/bookmarks/:id" component={FinderBookmarks} />

        <Route
          path="/finder/invoices"
          component={FinderInvoices}
          exact={true}
        />

        <Route
          path="/finder/subscription"
          component={FinderSubscription}
          exact={true}
        />
        <Route
          path="/finder/dashboard"
          component={FinderDashboard}
          exact={true}
        />

        <Route
          path="/finder/intelligence"
          // component={FinderIntelligenceDashboard}
          component={FinderIntelligenceImports}
          exact={true}
        />
        <Route
          path="/finder/intelligence/import"
          component={FinderIntelligenceImport}
          exact={true}
        />
        <Route
          path="/finder/intelligence/import/file"
          component={FinderIntelligenceImportFile}
          exact={true}
        />
        <Route
          path="/finder/intelligence/import/copypaste"
          component={FinderIntelligenceImportCopyPaste}
          exact={true}
        />
        <Route
          path="/finder/intelligence/imports"
          component={FinderIntelligenceImports}
          exact={true}
        />
        <Route
          path="/finder/intelligence/imports/:id"
          component={FinderIntelligenceImports}
        />

        <Route
          path="/finder/vision"
          component={FinderVisionImports}
          exact={true}
        />
        <Route
          path="/finder/vision/imports"
          component={FinderVisionImports}
          exact={true}
        />
        <Route
          path="/finder/vision/imports/:id"
          component={FinderVisionImports}
        />
        <Route
          path="/finder/vision/import"
          component={FinderVisionImport}
          exact={true}
        />

        <Route
          path="/distributors"
          component={DistributorsIndex}
          exact={true}
        />
        <Route path="/distributors/:id" component={DistributorsIndex} />

        <Route path="/simulations" component={SimulationsCollection} />
        <Route path="/simulation/:id" component={SimulationDetail} />
        <Route path="/simulateur" component={Simulator} />

        <Route path="/degustation/produit" component={Soon} />
        <Route path="/sac/bouteilles" component={Soon} />
        <Route path="/vitrines/chez/monop" component={Soon} />
        <Route path="/vitrines/chez/nysa" component={Soon} />
        <Route path="/presentoirs/sur/comptoir" component={Soon} />

        <Route path="/webinaires" component={Soon} />
        <Route path="/newsletter" component={Soon} />
        <Route path="/facebook/instagram" component={Soon} />
        <Route path="/publicite/sur/bottl" component={Soon} />

        <Route path="/consulting" component={Soon} />
        <Route path="/panels/cavistes" component={Soon} />
        <Route path="/sondage/cavistes" component={Soon} />

        <Route path="/profile" component={Profile} />
        <Route path="/notifications" component={Notifications} />

        <Route path="/insight/campaigns" component={InsightCampaigns} />
        <Route path="/insight/campaign/:id" component={InsightCampaign} />
        <Route path="/insight/invoices" component={InsightInvoices} />
        <Route
          path="/insight/participation/:campaignid/:id"
          component={InsightParticipation}
        />

        <Route path="/insights/studies" component={InsightsStudies} exact />
        <Route path="/insights/studies/:id" component={InsightsStudy} exact />

        <Route path="/survey/studies" component={InsightsStudies} exact />

        <Route path="/error" component={Errors} />
      </React.Fragment>
    ) : (
      <Redirect to={{ pathname: '/login' }} />
    )}
  </Switch>
);

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Pages} />
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route exact path="/login" component={LogIn} />
        <Route path="/" component={wrappedRoutes} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { RTLProps } from '../../../../shared/prop-types/ReducerProps';

const rows = [
  {
    id: 'id',
    disablePadding: false,
    label: 'ID',
  },
  {
    id: 'title',
    disablePadding: false,
    label: 'Titre',
  },
  {
    id: 'short_desc',
    disablePadding: false,
    label: 'Description',
  },
  {
    id: 'created_at',
    disablePadding: false,
    label: 'Date de publication',
  },
];

class MatTableHead extends PureComponent {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
    rtl: RTLProps.isRequired,
  };

  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, rtl, forStudiesAvailable } = this.props;

    return (
      <TableHead>
        <TableRow>
          {forStudiesAvailable && (
            <>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
              >
                ID
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
              >
                Nom
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
                style={{ textAlign: 'center' }}
              >
                Répondants
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
                style={{ textAlign: 'center' }}
              >
                Questions
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
                style={{ textAlign: 'center' }}
              >
                Date de publication
              </TableCell>
            </>
          )}
          {!forStudiesAvailable && (
            <>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
              >
                ID
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
              >
                Nom
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
              >
                Description
              </TableCell>
              <TableCell
                className="material-table__cell material-table__cell-right"
                align={rtl.direction === 'rtl' ? 'right' : 'left'}
                padding={'default'}
              >
                Date de publication
              </TableCell>
            </>
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default connect((state) => ({
  rtl: state.rtl,
}))(MatTableHead);
